@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-main: #FECC00;
    --secondary-main: #000;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



::-webkit-scrollbar-thumb {
    /* width: 5px!important; */
    background-color: #a0a0a0 !important;
    border-radius: 10px !important;
}

::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
    margin-top: 10px !important;
    padding-top: 5px !important;
}